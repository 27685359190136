/* eslint-disable react-hooks/exhaustive-deps */
import http from './http';
import { httpStats } from './http';
import { httpServices } from './http';
import { NewsClass } from '../apps/news/models/news';
import { CategoryClass } from 'apps/categories/models/category';
import { EventClass } from 'apps/events/models/event';
import { PageClass } from 'apps/pages/models/page';
import { DocumentClass } from 'apps/documents/models/document';
import { MainSectionClass } from 'apps/main_sections/models/main_section';
import { TextClass } from 'apps/texts/models/text';
import { GalleryClass } from 'apps/galleries/models/gallery';
import { PlaceClass } from 'apps/places/models/place';
import { CustomWidgetClass } from 'apps/custom_widgets/models/custom_widget';
import { ActivityClass } from 'apps/activities/models/activity';
import env from '../env';
import { Service, ServiceClass } from 'apps/services/models/service';
import { ServiceCategory } from 'apps/services/models/service_category';


class ApiService {

    // Login
    async login(formData) {
        let result = await http.post('login/', {...formData });
        return result;
    }

    // Logout
    async logout() {
        let result = await http.post('logout/');
        return result.data;
    }

    //Get Local Settings
    async getLocalSettings() {
        let result = await http.get('local_settings/');
        return result.data[0];
    }

    //Edit Website Settings
    async editLocalSettings(id, formData) {
        let result = await http.patch('local_settings/' + id + '/', {...formData });
        return result;
    }

    //Get Page
    async getPage(slug) {
        let result = await http.get('pages/' + slug);
        return result.data;
    }

    //Get Page List
    async getPages() {
        let result = await http.get('pages/');
        return result.data.map((data) => new PageClass(data));
    }

    //Create Page
    async createPage(formData) {
        let result = await http.post('pages/', {...formData });
        return result;
    }

    //Edit Page
    async editPage(slug, formData) {
        let result = await http.patch('pages/' + slug + '/', {...formData });
        return result;
    }

    //Delete Page
    async deletePage(slug) {
        let result = await http.delete('pages/' + slug + '/');
        return result;
    }

    //Get Category
    async getCategory(slug) {
        let result = await http.get('categories/' + slug);
        return result.data; 
    }

    //Get CategoryList
    async getCategoryList() {
        let result = await http.get('categories/');
        return result.data.map((data) => new CategoryClass(data)); 
    }

    //Create Category
    async createCategory(formData) {
        let result = await http.post('categories/', {...formData });
        return result;
    }

    //Edit Category
    async editCategory(slug, formData) {
        let result = await http.patch('categories/' + slug + '/', {...formData });
        return result;
    }

    //Delete Category
    async deleteCategory(slug) {
        let result = await http.delete('categories/' + slug + '/');
        return result;
    }

    //Get News
    async getNews(slug) {        
        let result = await http.get('news/' + slug);       
        return result.data;
    }

    //Get News List
    async getNewsList(category) {  
        let result; 
        if(!category){
            result = await http.get('news/'); 
        } else {
            result = await http.get('news/category/' + category); 
        }
              
        return result.data.map((data) => new NewsClass(data));
    }

    //Get Sticky News
    async getStickyNews() {        
        let result = await http.get('news/sticky');       
        return result.data[0];
    }

    //Get Latest News
    async getLatestNews() {        
        let result = await http.get('news/latest');       
        return result.data.map((data) => new NewsClass(data));
    }

    //Get Latest News Admin
    async getLatestNewsAdmin() {        
        let result = await http.get('news/latest_admin');       
        return result.data.map((data) => new NewsClass(data));
    }

    //Get Latest News By Category
    async getLatestNewsByCategory(category) {        
        let result = await http.get('news/latest_by_category/' + category);       
        return result.data.map((data) => new NewsClass(data));
    }

    //Get Latest News By Tag
    async getLatestNewsByTag(tag) {        
        let result = await http.get('news/latest_by_tag/' + tag);       
        return result.data.map((data) => new NewsClass(data));
    }

    //Get News By Office
    async getNewsByOffice(office) {        
        let result = await http.get('news/news_by_office/' + office);       
        return result.data.map((data) => new NewsClass(data));
    }

    //Get News By Tag
    async getNewsByTag(tag) {        
        let result = await http.get('news/news_by_tag/' + tag);       
        return result.data.map((data) => new NewsClass(data));
    }

    //Create News
    async createNews(formData) {
        let result = await http.post('news/', {...formData });
        return result;
    }

    //Edit News
    async editNews(slug, formData) {
        let result = await http.patch('news/' + slug + '/', {...formData });
        return result;
    }

    //Delete News
    async deleteNews(slug) {
        let result = await http.delete('news/' + slug + '/');
        return result;
    }

    //Get Event
    async getEvent(slug) {        
        let result = await http.get('events/' + slug);       
        return result.data;
    }

    //Get Events List
    async getEventsList(category) {  
        let result; 
        if(!category){
            result = await http.get('events/'); 
        } else {
            result = await http.get('events/category/' + category); 
        }
              
        return result.data.map((data) => new EventClass(data));
    }

    //Get Latest Events
    async getLatestEvents() {        
        let result = await http.get('events/latest/');       
        return result.data.map((data) => new EventClass(data));
    }

    //Get Latest Events Admin
    async getLatestEventsAdmin() {        
        let result = await http.get('events/latest_admin/');       
        return result.data.map((data) => new EventClass(data));
    }

    //Get Latest Events By Tag
    async getLatestEventsByTag(tag) {        
        let result = await http.get('events/latest_by_tag/' + tag);       
        return result.data.map((data) => new EventClass(data));
    }

    //Get Latest Events By Category
    async getLatestEventsByCategory(category) {        
        let result = await http.get('events/latest_by_category/' + category);       
        return result.data.map((data) => new EventClass(data));
    }

    //Get Events By Tag
    async getEventsByTag(tag) {        
        let result = await http.get('events/events_by_tag/' + tag);       
        return result.data.map((data) => new EventClass(data));
    }

    //Get Events By Office
    async getEventsByOffice(office) {        
        let result = await http.get('events/events_by_office/' + office);       
        return result.data.map((data) => new EventClass(data));
    }

    //Create Event
    async createEvent(formData) {
        let result = await http.post('events/', {...formData });
        return result;
    }

    //Edit Event
    async editEvent(slug, formData) {
        let result = await http.patch('events/' + slug + '/', {...formData });
        return result;
    }

    //Delete Event
    async deleteEvent(slug) {
        let result = await http.delete('events/' + slug + '/');
        return result;
    }

    //Get Text
    async getText(id) {
        let result = await http.get('texts/' + id);       
        return result.data;
    }

    //Get Text List
    async getTexts() {
        let result = await http.get('texts/');       
        return result.data.map((data) => new TextClass(data));
    }

    //Create Text
    async createText(formData) {
        let result = await http.post('texts/', {...formData });
        return result;
    }

    //Edit Text
    async editText(id, formData) {
        let result = await http.patch('texts/' + id + '/', {...formData });
        return result;
    }

    //Delete Event
    async deleteText(id) {
        let result = await http.delete('texts/' + id + '/');
        return result;
    }

    //Get Galleries
    async getGalleries() {
        let result = await http.get('galleries/');       
        return result.data.map((data) => new GalleryClass(data));
    }

    //Get Gallery
    async getGallery(id) {
        let result = await http.get('galleries/' + id);       
        return result.data;
    }

    //Create Gallery
    async createGallery(formData) {
        let result = await http.post('galleries/', {...formData });
        return result;
    } 

    //Edit Gallery
    async editGallery(id, formData) {
        let result = await http.patch('galleries/' + id + '/', {...formData });
        return result;
    }

    //Delete Gallery
    async deleteGallery(id) {
        let result = await http.delete('galleries/' + id + '/');
        return result;
    }

    //Get Places
    async getPlaces(category) {
        let result;
        if(!category){
            result = await http.get('places/'); 
        } else {
            result = await http.get('places/category/' + category); 
        }   
        return result.data.map((data) => new PlaceClass(data));
    }

    //Get Place
    async getPlace(slug) {
        let result = await http.get('places/' + slug);       
        return result.data;
    }

    //Create Place
    async createPlace(formData) {
        let result = await http.post('places/', {...formData });
        return result;
    } 

    //Edit Place
    async editPlace(id, formData) {
        let result = await http.patch('places/' + id + '/', {...formData });
        return result;
    }

    //Delete Place
    async deletePlace(id) {
        let result = await http.delete('places/' + id + '/');
        return result;
    }

    //Get Documents
    async getDocuments() {
        let result = await http.get('documents/');       
        return result.data.map((data) => new DocumentClass(data));
    }

    //Get Document
    async getDocument(slug) {
        let result = await http.get('documents/' + slug);       
        return result.data;
    }

    //Get Latest Documents Admin
    async getLatestDocumentsAdmin() {        
        let result = await http.get('documents/latest_admin/');       
        return result.data.map((data) => new DocumentClass(data));
    }

    //Get Latest Documents By Category
    async getLatestDocumentsByCategory(category) {        
        let result = await http.get('documents/latest_by_category/' + category);       
        return result.data.map((data) => new DocumentClass(data));
    }

    //Get Latest Documents By Tag
    async getLatestDocumentsByTag(tag) {        
        let result = await http.get('documents/latest_by_tag/' + tag);       
        return result.data.map((data) => new DocumentClass(data));
    }

    //Get Documents By Tag
    async getDocumentsByTag(tag) {        
        let result = await http.get('documents/documents_by_tag/' + tag);       
        return result.data.map((data) => new DocumentClass(data));
    }

    //Get Documents By Category
    async getDocumentsByCategory(category) {        
        let result = await http.get('documents/documents_by_category/' + category);       
        return result.data.map((data) => new DocumentClass(data));
    }

    //Create Document
    async createDocument(formData) {
        let result = await http.post('documents/', {...formData });
        return result;
    }

    //Edit Document
    async editDocument(slug, formData) {
        let result = await http.patch('documents/' + slug + '/', {...formData });
        return result;
    }

    //Delete Document
    async deleteDocument(slug) {
        let result = await http.delete('documents/' + slug + '/');
        return result;
    }

    //Get Main Sections
    async getMainSections() {
        let result = await http.get('main_sections/');       
        return result.data.map((data) => new MainSectionClass(data));
    }

    //Get Custom Widgets
    async getCustomWidgets() {
        let result = await http.get('custom_widgets/');       
        return result.data.map((data) => new CustomWidgetClass(data));
    }

    //Get Custom Widget
    async getCustomWidget(id) {
        let result = await http.get('custom_widgets/' + id);       
        return result.data;
    }

    //Create Custom Widget
    async createCustomWidget(formData) {
        let result = await http.post('custom_widgets/', {...formData });
        return result;
    }

    //Edit Custom Widget
    async editCustomWidget(id, formData) {
        let result = await http.patch('custom_widgets/' + id + '/', {...formData });
        return result;
    }

    //Delete Custom Widget
    async deleteCustomWidget(id) {
        let result = await http.delete('custom_widgets/' + id + '/');
        return result;
    }

    //Get Activities
    async getActivities() {
        let result = await http.get('activities/');       
        return result.data.map((data) => new ActivityClass(data));
    }    

    //Upload File
    async uploadFile(file, source) {
        const formData = new FormData();

        switch(source) {
            case "tinymce_news":
                formData.append("source", "tinymce_news");
                break;

            case "tinymce_event":
                formData.append("source", "tinymce_event");
                break;

            case "tinymce_text":
                formData.append("source", "tinymce_text");
                break;

            case "tinymce_category":
                formData.append("source", "tinymce_category");
                break;

            // case "tinymce_tag":
            //     formData.append("source", "tinymce_tag");
            //     break;

            case "tinymce_custom_widget":
                formData.append("source", "tinymce_custom_widget");
                break;

            default:
                formData.append("source", "standard");
        }
        
        formData.append("file", file);

        const filenameHeaderValue = 'inline;filename=' + encodeURIComponent(file.name);

        const result = await http.put('/file/upload/', formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': filenameHeaderValue,
                    "Access-Control-Allow-Origin": "*"
                }
            }
        );
        return result.data;
    }

    //Get Analytics Stats
    async getStats(request) {        
        let result = await httpStats.get("index.php?module=API&format=json&token_auth="+ env.ANALYTICS_AUTH_TOKEN + "&" + request);
        return result.data;
    }

    //Get Folder File List
    async getFolderFileList(path) {
        let result = await http.get('readdir/?folder_path=' + path);
        return result.data;
    }

    //Get Services
    async getService(slug) {
        var result = await httpServices.get(`/services/${slug}/`);
        return new Service(result.data);
    } 

    //Get Services
    async getServices() {
        let result = await httpServices.get('services');
        return result.data.map((data) => new ServiceClass(data));
    }

    //Get Services By Tag
    async getServicesByTag(slug) {
        let result = await httpServices.get('services/?tag=' + slug);
        return result.data.map((data) => new ServiceClass(data));
    }

    //Get Service Category
    async getServiceCategory(slug) {
        let result = await httpServices.get(`/service_categories/${slug}/`);
        return new ServiceCategory(result.data);
    }

    //Get Service Categories
    async getServiceCategories() {
        let result = await httpServices.get(`/service_categories/`);
        return result.data.map((data) => new ServiceCategory(data));
    }

    //Get Image List
    async getImageList(folder) {
        let result = await http.get(`image-list/` + folder);
        return result.data;
    }
  
}

export default new ApiService();


