/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Card, CardBody, CardCategory, CardTitle, Container, Icon, Row, Col, Spinner } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import ApiService from '../../../services/api';
import Pagination from 'pa_kit/pagination';
import moment from 'moment';
import env from '../../../env';
import parse from 'html-react-parser';

const WidgetNews = (props) => {

    const [ widget ] = useState(props.widget);

    const [ newsList, setNewsList ] = useState();
    const [ currentNews, setCurrentNews ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const [ paginationState, setPaginationState ] = useState({
        currentPage: 1,
        newsPerPage: widget.data.show_number
    });

    const handlePageChange = (pageNumber) => {
        setPaginationState({ ...paginationState, currentPage: pageNumber });
    };

    let category;
    let id_tag;
    let id_office;

    let show = widget.data.show_number;

    let page = props.page || '';    

    if(widget.data.category) {
        if(widget.data.category === 'all') {
            category = "";
        } else {
            category = widget.data.category;
        } 
    }

    if(widget.data.id_tag) {
        id_tag = widget.data.id_tag;
    }
    
    if(widget.data.id_office) {
        id_office = widget.data.id_office;
    } 

    const fetchNewsList = async() => {

        let newsListData;

        if(!newsList) {

            if(category !== undefined) {
                if(show > 4) {
                    newsListData = await ApiService.getNewsList(category);
                    newsListData = newsListData.filter(news => news.state === 'active');
                } else {
                    if(category === "") {
                        newsListData = await ApiService.getLatestNews();
                    } else {
                        newsListData = await ApiService.getLatestNewsByCategory(category);
                    }
                    
                }                
            } else if(id_tag !== undefined) {
                newsListData = await ApiService.getNewsByTag(id_tag);
                // newsListData = newsListData.filter(news => news.tags.some(tag => tag.id === id_tag));
                // newsListData = newsListData.filter(news => news.state === 'active');
            } else if(id_office !== undefined) {
                newsListData = await ApiService.getNewsByOffice(id_office);                
                // newsListData = newsListData.filter(news => (news.office?.id) === id_office);
                // newsListData = newsListData.filter(news => news.state === 'active');
            } 

            setNewsList(newsListData);
            setIsLoading(false);
        } else {
            newsListData = newsList;            
        }               
        
        const indexOfLastElement = paginationState.currentPage * paginationState.newsPerPage;
        const indexOfFirstElement = indexOfLastElement - paginationState.newsPerPage;
        const currentNewsListData = newsListData.slice(indexOfFirstElement, indexOfLastElement);
        setCurrentNews(currentNewsListData);
   
    }

    useEffect(() => {
        fetchNewsList();
    }, [paginationState]);    

    if(isLoading) return <Container>{page !== 'tag' && <Spinner active />}</Container>
    if(newsList && newsList.length === 0) return page !== 'tag' && <Container>Nessun Risultato</Container>;

    if(show > 4) return <>
        {page === 'tag' && newsList && newsList.length !== 0 &&
            <Container className="mt-5 pt-5">
                <h2 className="news-by-tags-title mb-4 px-1">Novità</h2>  
            </Container>
        }
        <h2 className="news-page-title mt-2">{widget.data.label ? widget.data.label : 'Elenco Notizie'}</h2>
        <Container id="news-elements-wrapper" fluid className={widget.data.class + '-news page-news-content'}>
            <Row>
            {currentNews && currentNews.map((news) => {
                return (
                        <Col key={news.slug} xs='12' lg='4' className="mt-2">
                            <Card className='card-img no-after shadow-sm'>
                            {news.image && news.image !== '' && news.image.substring(news.image.lastIndexOf('/') + 1) !== 'default.jpg' && 
                                <div className='img-responsive-wrapper'>
                                    <div className='img-responsive img-responsive-panoramic'>
                                        <figure className='img-wrapper'>
                                            <img alt="Copertina Notizia" className="img-fluid" src={env.BACKEND_BASE_URL + news.image || ''} title={news.title} />
                                        </figure>
                                    </div>
                                </div>
                            }   
                                <CardBody className="px-4">
                                    <CardCategory date={moment(news.date_news).format('DD/MM/YYYY')} iconName="it-horn"> 
                                        {news.category.name} 
                                    </CardCategory>
                                    <CardTitle className="fw-semibold"> <a className="news-title-link" href={'/novita/'+ news.category.slug + '/' + news.slug + '/'}>{news.title}</a> </CardTitle>
                                    <div className="mt-2 news-description mb-5">
                                        {parse((news.content.description.description).replace(/(<([^>]+)>)/gi, "").substring(0, 100) + '...')}
                                    </div>
                                    <a className="read-more position-relative" href={'/novita/' + news.category.slug + '/' + news.slug + '/'}>Leggi di più <Icon icon="it-arrow-right" /></a>
                                    <div className="mt-5">
                                    {news.tags && news.tags.map((tag) => 
                                        <div key={tag.slug} className={"chip chip-simple chip-primary me-2"}>
                                            <a className="chip-label" href={"/argomenti/" + tag.slug + '/'}>{tag.name}</a>
                                        </div>
                                    )} 
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>                    
                )
            })}
            </Row>
            <Pagination
                elementsPerPage={paginationState.newsPerPage}
                totalElements={newsList && newsList.length}
                currentPage={paginationState.currentPage}
                onPageChange={handlePageChange}
                type="news"
            />  
        </Container>        
    </>;

    return <>
        <h2 className="places-page-title mt-5">{widget.data.label ? widget.data.label : 'Elenco Notizie'}</h2>
        <Container fluid className={widget.data.class + '-news page-news-content'}>
            <Row>
            {newsList && newsList.slice(0, (show/2)).map((news) => {
                return (
                        <Col key={news.slug} xs='12' lg='4' className="mt-2">
                            <Card className='card-img no-after shadow-sm'>
                            {news.image && news.image !== '' && news.image.substring(news.image.lastIndexOf('/') + 1) !== 'default.jpg' && 
                                <div className='img-responsive-wrapper'>
                                    <div className='img-responsive img-responsive-panoramic'>
                                        <figure className='img-wrapper'>
                                            <img alt="Copertina Notizia" className="img-fluid" src={env.BACKEND_BASE_URL + news.image || ''} title={news.title} />
                                        </figure>
                                    </div>
                                </div>
                            }   
                                <CardBody className="px-4">
                                    <CardCategory date={moment(news.date_news).format('DD/MM/YYYY')} iconName="it-horn"> 
                                        {news.category.name} 
                                    </CardCategory>
                                    <CardTitle className="fw-semibold"> <a className="news-title-link" href={'/novita/'+ news.category.slug + '/' + news.slug + '/'}>{news.title}</a> </CardTitle>
                                    <div className="mt-2 news-description mb-5">
                                        {parse((news.content.description.description).substring(0, 100).replace(/(<([^>]+)>)/gi, "") + '...')}
                                    </div>
                                    <a className="read-more position-relative" href={'/novita/' + news.category.slug + '/' + news.slug + '/'}>Leggi di più <Icon icon="it-arrow-right" /></a>
                                    <div className="mt-5">
                                    {news.tags && news.tags.map((tag) => 
                                        <div key={tag.slug} className={"chip chip-simple chip-primary me-2"}>
                                            <a className="chip-label" href={"/argomenti/" + tag.slug + '/'}>{tag.name}</a>
                                        </div>
                                    )} 
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>                    
                )
            })}
                <Col xs='12' lg='4'>
                {newsList && newsList.slice((show/2), show).map((news) => {
                    return (
                            <Col key={news.slug} xs='12' lg='12'>
                                <Card className="mt-2 shadow-sm">
                                    <CardBody>
                                        <CardCategory date={moment(news.date_news).format('DD/MM/YYYY')} iconName="it-horn"> 
                                            {news.category.name} 
                                        </CardCategory>
                                        <CardTitle className="fw-semibold"> <a className="news-title-link" href={'/novita/'+ news.category.slug + '/' + news.slug + '/'}>{news.title}</a> </CardTitle>
                                        <div className="mt-2 news-description mb-5">
                                            {parse((news.content.description.description).substring(0, 100).replace(/(<([^>]+)>)/gi, "") + '...')}
                                        </div>
                                        <a className="read-more position-relative" href={'/novita/' + news.category.slug + '/' + news.slug + '/'}>Leggi di più <Icon icon="it-arrow-right" /></a>
                                    </CardBody>
                                </Card>
                            </Col>                    
                    )
                })}
                </Col>
            </Row>
        </Container>        
    </>;
}

export default WidgetNews;